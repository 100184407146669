/* Réinitialisation des styles par défaut */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Style de base pour le corps de la page */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f2f2f2;
  color: #333;
}

/* Conteneur principal */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* En-tête */
.header {
  text-align: center;
  padding: 20px 0;
}

.header h1 {
  font-size: 2.5em;
  color: #333;
}

/* Liens */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Boutons */
button,
input[type="submit"] {
  display: inline-block;
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button {
  width: 100%;
}

button:hover,
input[type="submit"]:hover {
  background-color: #555;
}

/* Styles pour les éléments de formulaire */
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: none;
  background-color: #f9f9f9;
  color: #333;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: #007bff;
}

fieldset {
  padding: 15px;
  margin: 15px;
}

/* Sections */
.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
}

.section p {
  margin-bottom: 20px;
  color: #555;
}

/* Pied de page */
.footer {
  text-align: center;
  padding: 20px 0;
  background-color: #333;
  color: #fff;
}

.footer p {
  margin-bottom: 0;
}


ul {
  padding: 20px;
}

.float_r {
  float: right;
}


/* Style de base pour le tableau */
table {
  border-collapse: collapse;
  overflow-wrap: break-word;
  width: 100%;


  table-layout: fixed;
}

/* Style des cellules du tableau */
td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

/* Style de l'en-tête du tableau */
th {
  background-color: #f2f2f2;
}

/* Style des lignes impaires du tableau */
tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* Style des lignes au survol */
tr:hover {
  background-color: #eaeaea;
}



/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 8% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 20px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.rouge {
  background: darkred;
}

.vert {
  background: darkgreen;
}


.flex-container {
  display: flex;
  justify-content: flex-end;
  gap: 50px;
}

.flex-none {
  flex: none;
}

.flex-end {
  justify-content: flex-end;
}

.flex-item-1 {
  flex: 1;
}

.flex-item-2 {
  flex: 2;
}

.flex-item-3 {
  flex: 3;
}

.flex-item-4 {
  flex: 4;
}

.flex-item-5 {
  flex: 5;
}

.flex-item-6 {
  flex: 6;
}